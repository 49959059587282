// Add your JS customizations here

(function($) {
    $( ".search-btn" ).on( "click", function() {
        $( ".site-header__search-form" ).slideToggle( "slow" );
      });
})(jQuery);


(function($) {
  $( ".simplefavorites-clear" ).on( "click", function() {
    setTimeout(function () {
      console.log('Reloading Page');
      location.reload(true);
    }, 1000);
  });
})(jQuery);

(function($) {
  $( ".simplefavorite-button" ).on( "click", function() {
    setTimeout(function () {
      console.log('Reloading Page');
      location.reload(true);
    }, 1000);
  });
})(jQuery);